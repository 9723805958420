import { constants } from '@/styles';
import { StyleFunction } from '@/styles/models';

export const radioStyles: StyleFunction = ({ theme }) => ({
  '.radio-selectable-wrapper': {
    cursor: 'pointer',
    border: `1px solid ${theme.borderAccent}`,
    borderRadius: constants.borderRadius.medium,
    padding: constants.offset.small,
    marginBottom: constants.offset.small,

    '&--selected': {
      borderColor: theme.borderPrimaryActive,
    },

    '&--no-border': {
      border: 'none',
    },

    '.radio-container .radio .checkmark': {
      left: 0,
    },
    '.additional-info': {
      marginBottom: 0,
    },
  },

  '.radio-container': {
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    paddingTop: constants.offset.xsmall,
    paddingBottom: constants.offset.xsmall,
    color: theme.textPrimary,

    '.radio': {
      position: 'relative',
      paddingLeft: constants.offset.xlarge,
      cursor: 'pointer',
      userSelect: 'none',
      color: theme.textPrimary,

      '&.focused': {
        '.checkmark': {
          boxShadow: `0px 0px 0px 3px ${theme.primaryFocus}`,
        },
      },

      '&.unchecked': {
        'input ~ .checkmark': {
          border: `1px solid ${theme.borderSecondary}`,
        },
      },

      '&.checked': {
        'input ~ .checkmark': {
          border: `1px solid ${theme.primary}`,
          backgroundColor: theme.backgroundPrimary,
        },

        '.checkmark:after': {
          left: 3,
          top: 3,
          width: 10,
          height: 10,
          borderRadius: '50%',
          backgroundColor: theme.primary,
        },
      },

      '&.disabled': {
        cursor: 'not-allowed',

        '& > *': {
          color: theme.textDisabled,
        },

        'input ~ .checkmark': {
          border: `1px solid ${theme.backgroundSecondaryDisabled}`,
          backgroundColor: theme.backgroundPrimary,

          '&:after': {
            backgroundColor: theme.backgroundSecondaryDisabled,
          },
        },
      },
    },

    '.radio-label': {
      color: theme.textPrimary,
      whiteSpace: 'nowrap',
    },

    input: {
      position: 'absolute',
      opacity: 0,
      height: 0,
      width: 0,
    },

    '.checkmark': {
      position: 'absolute',
      top: 0,
      left: 4,
      height: 16,
      width: 16,
      backgroundColor: theme.backgroundPrimary,
      borderRadius: '50%',
      border: `1px solid ${theme.borderSecondary}`,
      transition: 'background-color .15s ease-in',

      '&:after': {
        content: '""',
        position: 'absolute',
        opacity: 0,
      },
    },

    'input ~ .checkmark:after': {
      opacity: 1,
    },
  },

  '.additional-info': {
    marginTop: constants.offset.small,
    color: theme.textPrimary,

    '&--disabled': {
      color: theme.textDisabled,
    },
  },

  '&:not(:last-of-type) .additional-info': {
    marginBottom: constants.offset.large,
  },
});
