import React, { FC } from 'react';

import { Field, FieldProps } from '../field';
import { Datepicker, type DatepickerProps } from './datepicker';
import { DATE_FORMATS } from '../../form/controls/datepicker/constants';

type FieldDatepickerProps = Omit<FieldProps, 'children'> &
  Omit<DatepickerProps, 'onChange'> & {
    className?: string;
    monthMode?: boolean;
    customDateFormat?: string;
  };

export const FieldDatepicker: FC<FieldDatepickerProps> = ({
  name,
  label,
  labelIcon,
  isFocusOnLabelEnabled,
  className,
  disabled,
  monthMode,
  customDateFormat,
  dateFormat = DATE_FORMATS.dd_MMMM_yyyy,
  dateFormatCalendar = DATE_FORMATS.dd_MMMM_yyyy,
  ...rest
}) => {
  return (
    <Field name={name} label={label} labelIcon={labelIcon} isFocusOnLabelEnabled={isFocusOnLabelEnabled} className={className}>
      {({ field: { value, ...field }, form: { values, setFieldValue } }) => {
        return (
          <Datepicker
            {...field}
            {...rest}
            name={name}
            dateFormat={dateFormat}
            dateFormatCalendar={dateFormatCalendar}
            disabled={disabled}
            monthMode={monthMode}
            selected={values[name]}
            onChange={date => setFieldValue(name, date)}
          />
        );
      }}
    </Field>
  );
};
