import dayjs from 'dayjs';

import { SelectOption } from '@/common/components/form-controls-deprecated';
import { Enum } from '@/common/utils';
import {
  getCurrentMonth,
  getCurrentQuarterEnd,
  getCurrentQuarterStart,
  getCurrentYear,
  getLastMonthEnd,
  getLastMonthStart,
  getLastQuarterEnd,
  getLastQuarterStart,
  getLastYear,
} from './functions';

export type Period = Enum<typeof Period>;
export const Period = Enum('LAST WEEK', 'LAST MONTH', 'LAST QUARTER', 'LAST YEAR', 'CURRENT MONTH', 'CURRENT QUARTER', 'CURRENT YEAR', 'CUSTOM PERIOD');

export const monthModePlaceholder = 'mm-yyyy';
export const dayModePlaceholder = 'dd-mm-yyyy';

export const monthModeDateFormat = ['MM.yyyy', 'MMMM yyyy', 'MM/yyyy', 'MM-yyyy'];
export const dayModeDateFormat = ['dd-MM-yyyy', 'dd.MM.yyyy', 'dd/MM/yyyy'];

export const CURRENT_MONTH_START = dayjs(`${getCurrentYear()}-${getCurrentMonth()}-1`).toDate();
export const CURRENT_MONTH_END = dayjs(`${getCurrentYear()}-${getCurrentMonth() + 1}-0`).toDate();

export const CURRENT_QUARTER_START = getCurrentQuarterStart();
export const CURRENT_QUARTER_END = getCurrentQuarterEnd();

export const CURRENT_YEAR_START = dayjs(`${getCurrentYear()}-1-1`).toDate();
export const CURRENT_YEAR_END = dayjs(`${getCurrentYear()}-12-31`).toDate();

export const LAST_MONTH_START = getLastMonthStart();
export const LAST_MONTH_END = getLastMonthEnd();

export const LAST_QUARTER_START = getLastQuarterStart();
export const LAST_QUARTER_END = getLastQuarterEnd();

export const LAST_YEAR_START = dayjs(`${getLastYear()}-1-1`).toDate();
export const LAST_YEAR_END = dayjs(`${getLastYear()}-12-31`).toDate();

export const datePeriodOptions: SelectOption[] = [
  {
    value: Period['CUSTOM PERIOD'],
    label: 'Custom Period',
    translationId: 'label.customPeriod',
  },
  {
    value: Period['CURRENT MONTH'],
    label: 'Current Month',
    translationId: 'label.currentMonth',
  },
  {
    value: Period['CURRENT QUARTER'],
    label: 'Current Quarter',
    translationId: 'label.currentQuarter',
  },
  {
    value: Period['CURRENT YEAR'],
    label: 'Current Year',
    translationId: 'label.currentYear',
  },
  {
    value: Period['LAST MONTH'],
    label: 'Last Month',
    translationId: 'label.lastMonth',
  },
  {
    value: Period['LAST QUARTER'],
    label: 'Last Quarter',
    translationId: 'label.lastQuarter',
  },
  {
    value: Period['LAST YEAR'],
    label: 'Last Year',
    translationId: 'label.lastYear',
  },
];
