import { constants } from '@/styles';
import { type StyleFunction } from '@/styles/models';

export const autocompleteInputStyle: StyleFunction = ({ theme }) => ({
  backgroundColor: theme.backgroundNinth,

  '.autocomplete-input': {
    'div.Mui-disabled': {
      input: {
        color: theme.borderPrimaryDisabled,
        WebkitTextFillColor: theme.borderPrimaryDisabled,
      },
    },

    '.MuiOutlinedInput-root': {
      color: theme.textPrimary,
      borderRadius: `${constants.borderRadius.small}px`,
      fontSize: 13,

      '.MuiOutlinedInput-notchedOutline': {
        transition: 'border-color .2s',
      },

      '&.Mui-focused fieldset': {
        border: `1px solid ${theme.borderPrimaryActive}`,
        boxShadow: `0 0 1px 1px ${theme.borderPrimaryActive} inset`,
        outline: 'none',
      },

      '&:hover:not(.Mui-focused):not(.Mui-disabled)': {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: theme.borderPrimary,
        },
      },
    },

    '.Mui-disabled': {
      cursor: 'not-allowed',
      color: theme.textDisabled,

      '.MuiAutocomplete-endAdornment': {
        display: 'none',
      },

      '.MuiOutlinedInput-notchedOutline': {
        border: `1px solid ${theme.borderPrimaryDisabled}`,
      },
    },

    '&--with-button': {
      '.MuiAutocomplete-input': {
        padding: '6px 4px 7px !important',
      },

      fieldset: {
        borderRight: 'none',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      },
    },

    '&--disabled': {
      backgroundColor: theme.backgroundPrimaryDisabled,

      '.MuiAutocomplete-input': {
        color: theme.borderPrimaryDisabled,
      },

      div: {
        paddingRight: '6px !important',

        '& > input::placeholder ': {
          color: `${theme.textDisabled} !important`,
        },
      },
    },

    '&--no-option-placeholder': {
      div: {
        '& > input::placeholder ': {
          color: theme.textPrimary,
          opacity: 1,
        },
      },

      fieldset: {
        borderColor: `${theme.borderPrimaryDisabled} !important`,
      },
    },
  },
});
